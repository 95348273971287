import React from 'react';
import './App.css';
import { WebAuthn } from 'oth3llo-sdk';

const apiKey = "f39ab834-1cb7-42ed-af2c-935ac0511123";

const LoadingModal = () => {
  return (
    <div className="loading-modal">
      <div className="loading-content">
        <svg className="loading-svg" viewBox="0 0 50 50">
          <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
        </svg>
        <p>Loading...</p>
      </div>
    </div>
  );
};

export const handleSignup = async (username) => {
  try {
    const credential = await WebAuthn.create(username, apiKey);
    if (credential) {
      const walletAddress = credential.walletAddress;
      //localStorage.setItem("walletAddress", walletAddress);
      console.log('Signup successful for : ', walletAddress);
      //window.alert('Signup successful, see console to check wallet address');
      //window.dispatchEvent(new Event('storage'));

      // Redirect to the specified URL
      window.location.href = `https://productionpui.page.link/othello?othelloAddress=${walletAddress}&puiID=${username}`;
    } else {
      alert("Failed to create wallet. Please try again.");
    }
  } catch (error) {
    console.error("Error creating wallet:", error);
  }
};

export const handleLogin = async (username) => {
  try {
    const encoder = new TextEncoder();
    const challenge = encoder.encode("random");
    const credential = await WebAuthn.get(challenge);
    if (credential) {
      const walletAddress = credential.walletAddress;
      console.log('Login successful:', credential.walletAddress);
      //window.alert('Login successful, see console to check wallet address');
      //localStorage.setItem("walletAddress", credential.walletAddress);
      //window.dispatchEvent(new Event('storage'));

      // Redirect to the specified URL
      window.location.href = `https://productionpui.page.link/othello?othelloAddress=${walletAddress}&puiID=${username}`;
    } else {
      alert('Login failed. Please try again.');
    }
  } catch (error) {
    console.error("Error during login:", error);
  }
};


export default LoadingModal;
